.title {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-weight: 800;
  color: #444649;
  margin-bottom: 10px;
}

.underline {
  border: 2px solid #aa0000;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5vh;
}
