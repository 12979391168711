.background {
  background-color: #003355;
  background-image: url(../images/bg3.svg);
  background-attachment: scroll;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  padding-top: 100px;
  padding-bottom: 120px;
}

.title-main {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  color: beige;
  font-size: 5rem;
}

.title-sub {
  color: beige;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.title-sub-2-box {
  width: 100px;
  height: 100px;
  text-align: center;
}

.title-sub-2 {
  margin: 0;
  font-size: 4rem;
}

.button {
  color: beige;
  border: 3px solid beige;
  border-radius: 50%;
  transition: 0.3s;
}

.button:hover {
  background-color: beige;
  color: #003355;
}

@media screen and (max-width: 1200px) {
  .title-tablet {
    font-size: 3rem;
  }

  .title-sub {
    font-size: 1.5rem;
  }

  .title-sub-2 {
    font-size: 3rem;
  }

  .title-sub-2-box {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 768px) {
  .title-tablet {
    font-size: 2.5rem;
  }

  .title-sub {
    font-size: 1rem;
  }

  .title-sub-2 {
    font-size: 2.5rem;
  }

  .title-sub-2-box {
    width: 50px;
    height: 50px;
  }

  .button {
    border: 2px solid beige;
  }
}

@media screen and (max-width: 576px) {
  .title-tablet {
    font-size: 2rem;
  }
  .title-sub-2 {
    font-size: 2rem;
  }

  .button {
    border: 2px solid beige;
  }
}

@media screen and (max-width: 320px) {
  .title-tablet {
    font-size: 1.5rem;
  }

  .title-sub {
    font-size: 0.75rem;
  }

  .title-sub-2 {
    font-size: 1.5rem;
  }

  .title-sub-2-box {
    width: 40px;
    height: 40px;
  }

  .button {
    border: 2px solid beige;
  }
}
