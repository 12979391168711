@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
.background {
  background-color: #003355;
  background-image: url(/static/media/bg3.a70c8c73.svg);
  background-attachment: scroll;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  padding-top: 100px;
  padding-bottom: 120px;
}

.title-main {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  color: beige;
  font-size: 5rem;
}

.title-sub {
  color: beige;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.title-sub-2-box {
  width: 100px;
  height: 100px;
  text-align: center;
}

.title-sub-2 {
  margin: 0;
  font-size: 4rem;
}

.button {
  color: beige;
  border: 3px solid beige;
  border-radius: 50%;
  transition: 0.3s;
}

.button:hover {
  background-color: beige;
  color: #003355;
}

@media screen and (max-width: 1200px) {
  .title-tablet {
    font-size: 3rem;
  }

  .title-sub {
    font-size: 1.5rem;
  }

  .title-sub-2 {
    font-size: 3rem;
  }

  .title-sub-2-box {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 768px) {
  .title-tablet {
    font-size: 2.5rem;
  }

  .title-sub {
    font-size: 1rem;
  }

  .title-sub-2 {
    font-size: 2.5rem;
  }

  .title-sub-2-box {
    width: 50px;
    height: 50px;
  }

  .button {
    border: 2px solid beige;
  }
}

@media screen and (max-width: 576px) {
  .title-tablet {
    font-size: 2rem;
  }
  .title-sub-2 {
    font-size: 2rem;
  }

  .button {
    border: 2px solid beige;
  }
}

@media screen and (max-width: 320px) {
  .title-tablet {
    font-size: 1.5rem;
  }

  .title-sub {
    font-size: 0.75rem;
  }

  .title-sub-2 {
    font-size: 1.5rem;
  }

  .title-sub-2-box {
    width: 40px;
    height: 40px;
  }

  .button {
    border: 2px solid beige;
  }
}

.title {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-weight: 800;
  color: #444649;
  margin-bottom: 10px;
}

.underline {
  border: 2px solid #aa0000;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5vh;
}

.card-1 {
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 1rem;
  color: #444649;
  text-align: justify;
}

@media screen and (max-width: 767px) {
  .card-1 {
    margin-bottom: 5vh;
  }
}

.card-icon {
  border-radius: 20px;
  padding: 1rem;
  color: #444649;
  text-align: center;
}

.card-image {
  padding: 0 0.5rem 1rem 0.5rem;
}

.card-image-inner {
  background-color: white;
  border-radius: 20px;
  padding: 0.7rem;
  box-shadow: 1px 2px #c5c5c5;
}

.card-image-desc {
  color: #858585;
  font-size: 0.75rem;
}

.card-image-text {
  font-size: 0.8rem;
  text-align: justify;
}

.card-image-tech {
  font-size: 0.75rem;
  margin-bottom: 0;
  margin-left: 0.2rem;
}

.title {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  margin-top: 0;
}

.margin-small {
  margin-bottom: 0.2rem;
}

.circle {
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 50%;
  background-color: #003355;
  color: beige;
  margin-left: auto;
  margin-right: auto;
}

.circle-small {
  width: 10px;
  height: 10px;
  margin-right: 0.2rem;
  border-radius: 50%;
  background-color: #003355;
  color: beige;
}

@media screen and (min-width: 768px) {
  .circle {
    width: 100px;
    height: 100px;
  }
}

/*
@media screen and (max-width: 576px) {
  p {
    text-align: left;
  }

  .card-image-text {
    text-align: left;
  }
}
*/

.logo-size {
  font-size: 1.5rem;
}
.text-colour {
  color: beige;
}

.center-items a:hover {
  color: white;
  font-weight: 800;
}

@font-face {
  font-family: "Helvetica400";
  src: local("Helvetica400"), url(/static/media/Helvetica400.06113bf7.ttf) format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: "Helvetica400", Helvetica, Arial, sans-serif;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}

.box-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

