.logo-size {
  font-size: 1.5rem;
}
.text-colour {
  color: beige;
}

.center-items a:hover {
  color: white;
  font-weight: 800;
}
