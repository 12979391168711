.card-1 {
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 1rem;
  color: #444649;
  text-align: justify;
}

@media screen and (max-width: 767px) {
  .card-1 {
    margin-bottom: 5vh;
  }
}

.card-icon {
  border-radius: 20px;
  padding: 1rem;
  color: #444649;
  text-align: center;
}

.card-image {
  padding: 0 0.5rem 1rem 0.5rem;
}

.card-image-inner {
  background-color: white;
  border-radius: 20px;
  padding: 0.7rem;
  box-shadow: 1px 2px #c5c5c5;
}

.card-image-desc {
  color: #858585;
  font-size: 0.75rem;
}

.card-image-text {
  font-size: 0.8rem;
  text-align: justify;
}

.card-image-tech {
  font-size: 0.75rem;
  margin-bottom: 0;
  margin-left: 0.2rem;
}

.title {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  margin-top: 0;
}

.margin-small {
  margin-bottom: 0.2rem;
}

.circle {
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 50%;
  background-color: #003355;
  color: beige;
  margin-left: auto;
  margin-right: auto;
}

.circle-small {
  width: 10px;
  height: 10px;
  margin-right: 0.2rem;
  border-radius: 50%;
  background-color: #003355;
  color: beige;
}

@media screen and (min-width: 768px) {
  .circle {
    width: 100px;
    height: 100px;
  }
}

/*
@media screen and (max-width: 576px) {
  p {
    text-align: left;
  }

  .card-image-text {
    text-align: left;
  }
}
*/
