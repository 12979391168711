@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");

@font-face {
  font-family: "Helvetica400";
  src: local("Helvetica400"), url("./fonts/Helvetica400.ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: "Helvetica400", Helvetica, Arial, sans-serif;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}

.box-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
